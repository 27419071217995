import { useClientStore } from "../stores/ClientStore.js"
import { useHotelStatusStore } from "../stores/HotelStatusStore.js"
import { useSubscriptionsStore } from "../stores/SubscriptionStore.js"

export async function globalRoutesGuards(to, from) {
    const { client } = useClientStore()
    const { hotel } = useHotelStatusStore()
    const { prosafeLicence } = useSubscriptionsStore()

    // * Verify if user is logged
    if (
        to.name !== 'login' &&
        to.name !== 'sign_up' &&
        to.name !== 'forgot_password' &&
        to.name !== 'reset_password' &&
        !client.isAuth
    ) return { name: 'login' }

    // * Redirect to ProSafe trial subscription
    if (
        prosafeLicence.subscriptionId == 1 &&
        client.isAuth &&
        to.name !== prosafeLicence.redirection
    ) return { name: prosafeLicence.redirection }

    // * Redirect to home if customer have a active subscription
    if (
        prosafeLicence.subscriptionId == 2 &&
        client.isAuth &&
        to.name === 'prosafe_licence' ||
        prosafeLicence.subscriptionId == 2 &&
        client.isAuth &&
        to.name === 'prosafe_trial'
    ) return { name: 'main_component' }

    // * Redirect to ProSafe licence subscription
    if (
        prosafeLicence.subscriptionId == 3 &&
        client.isAuth &&
        to.name !== prosafeLicence.redirection
    ) return { name: prosafeLicence.redirection }

    // * Verify if hotel setup is complete
    if (
        to.name != 'hotel_setup' &&
        to.name != 'hotel_information' &&
        to.name != 'hotel_floors' &&
        to.name != 'hotel_floors_add' &&
        to.name != 'hotel_floors_edit' &&
        to.name != 'hotel_rooms' &&
        to.name != 'hotel_rooms_add' &&
        to.name != 'hotel_rooms_edit' &&
        to.name != 'hotel_areas' &&
        to.name != 'hotel_areas_add' &&
        to.name != 'hotel_areas_edit' &&
        !hotel.isCompleted &&
        client.isAuth &&
        prosafeLicence.subscriptionId !== 1 &&
        prosafeLicence.subscriptionId !== 3
    ) return { name: 'hotel_setup' }

    // * Redirect to home if user is auth and try enter to login path
    if (to.name == 'login' && client.isAuth) return { name: 'main_component' }
}
import { createRouter, createWebHistory, onBeforeRouteLeave } from 'vue-router'
import { globalRoutesGuards } from '../guards/GlobalRoutesGuards.js'
import { associatesAddGuard } from '../guards/AssociatesAddGuard.js'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/Login/Views/Login.vue')
  },
  {
    path: '/sign_up',
    name: 'sign_up',
    component: () => import('@/modules/SignUp/Views/SignUp.vue')
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import('@/modules/ForgotPassword/Views/ForgotPassword.vue')
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: () => import('@/modules/ResetPassword/Views/ResetPassword.vue')
  },
  {
    path: '/',
    name: 'main_component',
    component: () => import('@/modules/Main/Views/Main.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/modules/Customer/Views/Customer.vue')
  },
  {
    path: '/hotel_setup',
    name: 'hotel_setup',
    component: () => import('@/modules/HotelSetupStatus/Views/HotelSetupStatus.vue')
  },
  {
    path: '/hotel_setup/info',
    name: 'hotel_information',
    component: () => import('@/modules/HotelSetupInformation/Views/HotelSetupInformation.vue')
  },
  {
    path: '/hotel_setup/floors',
    name: 'hotel_floors',
    component: () => import('@/modules/HotelSetupFloors/Views/HotelSetupFloors.vue'),
  },
  {
    path: '/hotel_setup/floors/add',
    name: 'hotel_floors_add',
    component: () => import('@/modules/HotelSetupFloors/Views/HotelSetupFloorsAdd.vue')
  },
  {
    path: '/hotel_setup/floors/edit/:floorId',
    name: 'hotel_floors_edit',
    props: true,
    component: () => import('@/modules/HotelSetupFloors/Views/HotelSetupFloorsEdit.vue')
  },
  {
    path: '/hotel_setup/rooms',
    name: 'hotel_rooms',
    component: () => import('@/modules/HotelSetupRooms/Views/HotelSetupRooms.vue')
  },
  {
    path: '/hotel_setup/rooms/add',
    name: 'hotel_rooms_add',
    component: () => import('@/modules/HotelSetupRooms/Views/HotelSetupRoomsAdd.vue')
  },
  {
    path: '/hotel_setup/rooms/edit/:roomId',
    name: 'hotel_rooms_edit',
    props: true,
    component: () => import('@/modules/HotelSetupRooms/Views/HotelSetupRoomsEdit.vue')
  },
  {
    path: '/hotel_setup/areas',
    name: 'hotel_areas',
    component: () => import('@/modules/HotelSetupAreas/Views/HotelSetupAreas.vue')
  },
  {
    path: '/hotel_setup/areas/add',
    name: 'hotel_areas_add',
    component: () => import('@/modules/HotelSetupAreas/Views/HotelSetupAreasAdd.vue')
  },
  {
    path: '/hotel_setup/areas/edit/:areaId',
    name: 'hotel_areas_edit',
    props: true,
    component: () => import('@/modules/HotelSetupAreas/Views/HotelSetupAreasEdit.vue')
  },
  {
    path: '/associates/add',
    name: 'associate_add',
    component: () => import('@/modules/Associates/Views/AssociatesAdd.vue'),
    beforeEnter: associatesAddGuard,
  },
  {
    path: '/associates/edit/:associateId',
    name: 'associate_edit',
    props: true,
    component: () => import('@/modules/Associates/Views/AssociatesEdit.vue')
  },
  {
    path: '/associates/subscriptions',
    name: 'associates_subscriptions',
    props: true,
    component: () => import('@/modules/AssociatesSubscriptions/Views/AssociatesSubscriptions.vue')
  },
  {
    path: '/associates_alert',
    name: 'associates_alert',
    component: () => import('@/modules/PanicAlertAssociatesList/Views/AlertAssociatesList.vue')
  },
  {
    path: '/alert_timeline/:alertId',
    name: 'alert_timeline',
    props: true,
    component: () => import('@/modules/PanicAlertAssociatesTracking/Views/AlertTracking.vue')
  },
  {
    path: '/alert/report/:alertId',
    name: 'alert_report',
    props: true,
    component: () => import('@/modules/AlertReport/Views/AlertReport.vue')

  },
  {
    path:  '/prosafe_licence',
    name: 'prosafe_licence',
    component: () => import('@/modules/Subscriptions/Views/ProsafeLicence.vue')
  },
  {
    path:  '/prosafe_trial',
    name: 'prosafe_trial',
    component: () => import('@/modules/Subscriptions/Views/ProsafeTrial.vue')
  },
  {
    path: '/incidents/add/:alertId?',
    name: 'incidents_add',
    props: true,
    component: () => import('@/modules/Incidents/Views/IncidentsAdd.vue')
  },
  {
    path: '/hotel_embargo/add',
    name: 'hotel_embargo_add',
    component: () => import('@/modules/HotelEmbargo/Views/HotelEmbargoAdd.vue')
  }
]

const router = createRouter({
  hashbang: false,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// * Auth guard validation
router.beforeEach(globalRoutesGuards);

export default router
